import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import ServiceDetailsTwo from "../containers/services/serv2";
import Bloggridtwo from "../containers/blog-grid/blog-grid-two";

const ServiceDetails = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>HT-Call | Assistance E-Commerce</title>
        <meta
          name="description"
          content="Service details page of React VOIP, Telecom and Cloud Services Template."
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Assistance E-Commerce" />
        {/* service details */}
        <ServiceDetailsTwo />
          <Bloggridtwo />
      </LayoutOne>
    </Fragment>
  );
};

export default ServiceDetails;

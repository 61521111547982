import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import HomeOne from "./pages/HomeOne";
import HomeTwo from "./pages/HomeTwo";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogLeftSidebar from "./pages/BlogLeftSidebar";
import BlogPost from "./pages/BlogPost";
import BlogPostLeftSidebar from "./pages/BlogPostLeftsSidebar";
import Contact from "./pages/Contact";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import ServiceDetails2 from "./pages/ServiceDetails2";
import ServiceDetails3 from "./pages/ServiceDetails3";
import ServiceDetails4 from "./pages/ServiceDetails4";
import ServiceDetailsLeftSidebar from "./pages/ServiceDetailsLeftSidebar";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={HomeOne}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/apropos"}`}
            component={About}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/service"}`}
            component={Service}
          />
          <Route
              path={`${process.env.PUBLIC_URL + "/assistance-commerciale"}`}
              component={ServiceDetails}
          />
          <Route
              path={`${process.env.PUBLIC_URL + "/assistance-e-commerce"}`}
              component={ServiceDetails2}
          />
          <Route
              path={`${process.env.PUBLIC_URL + "/delegation-des-rh"}`}
              component={ServiceDetails3}
          />
          <Route
              path={`${process.env.PUBLIC_URL + "/teleprospection"}`}
              component={ServiceDetails4}
          />
          <Route
              path={process.env.PUBLIC_URL + "/404"}
            component={NotFound}
          />
          <Route exact component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;

import React from "react";
import styles from "./AboutContentOne.module.scss";
import SectionTitleTwo from "../../../components/UI/section-title/section-title-two";
import aboutContent from "../../../data/about-content/about-content-one.json";

const AboutContentOne = () => {
  return (
    <div className={`${styles.voopoAboutArea} position-relative bg--cart-11`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="aboutInner">
              <SectionTitleTwo
                title={aboutContent.title}
                text={aboutContent.subTitle}
              />
              <div className={styles.aboutInner}>
                  <p>Depuis sa création à Oujda en septembre 2016, HT-Call intervient au quotidien, aussi bien aux côtés des multinationales que des PME, des TPE et des professions libérales. Nous communiquons en leur nom auprès de leurs clients et nous sommes conscients de la responsabilité qui nous ont confié.</p>
                  <p>Nous proposons des solutions de contacts pour toutes les prestations de gestion clients, d'appels entrants et sortants, d’assistance commerciale et E-commerce.</p>
                  <p>race à nos années d’expérience qui couvre plusieurs champs d’application dans les secteurs traditionnels, nous sommes fiers de donner chaque jour, aux clients qui nous font confiance, les preuves de notre expertise.</p>
                  </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.aboutThumb}>
        <img
          src={require("./../../../assets/images/about/" + aboutContent.image)}
          alt="sdsddsds"
        />
      </div>

    </div>

  );
};

export default AboutContentOne;

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { IoLogoTwitter, IoLogoVimeo, IoLogoPinterest } from "react-icons/io";
import { MdExpandLess } from "react-icons/md";
import styles from "./Footer.module.scss";
import logo from "../../../assets/images/logo/ht11.png";
import logoDark from "../../../assets/images/logo/ht11.png";
import mail from "../../../assets/images/icons/mail.png";
import phone from "../../../assets/images/icons/mail.png";

const Footer = ({ footerBg }) => {
  const année = new Date().getFullYear();
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

                const handleScroll = () => {
                setScroll(window.scrollY);
              };
                return (
                <footer
                    className={`footer-area ${footerBg === "white" ? styles.footer2 : ""}`}
                >
                  <div
                      className={`${styles.footerTop} ${
                          footerBg === "white" ? "bg--cart-7" : "bg--cart-2"
                      }`}
                  >
                    <div className="container">
                      <div className="row">
                        {/* Start Single Wedget */}
                        <div className="col-12 col-sm-6 col-lg-3">
                          <div className={styles.singleWidget}>
                            <div className={styles.logo}>
                              <Link to={process.env.PUBLIC_URL + "/"}>
                                <img
                                    src={footerBg === "white" ? logoDark : logo}
                                    alt="voip"
                                />
                              </Link>
                            </div>
                            <div className={styles.content}>
                              <p>
                                Centre spécialisé en télémarketing et en  de la relation client.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* End Single Wedget */}


                        {/* Start Single Wedget */}
                        <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12 sm__mt--40 md__mt--40">
                          <div className={styles.singleWidget}>
                            <h2 className={styles.ftTitle}>Nos services</h2>
                            <ul className={styles.ftContactLink}>
                              <li>
                                <Link to={process.env.PUBLIC_URL + "/assistance-commerciale"}>Assistance commerciale</Link>
                              </li>
                              <li>
                                <Link to={process.env.PUBLIC_URL + "/assistance-e-commerce"}>Assistance E-Commerce</Link>
                              </li>
                              <li>
                                <Link to={process.env.PUBLIC_URL + "/delegation-des-rh"}>Délégation des RH</Link>
                              </li>
                              <li>
                                <Link to={process.env.PUBLIC_URL + "/teleprospection"}>Téléprospection</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* End Single Wedget */}
                        {/* Start Single Wedget */}
                        <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12 sm__mt--40 md__mt--40">
                          <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Contact</h2>
                <ul className={styles.ftContactLink}>

                  <li>

                    <a href="mailto:youssef.bouhafs@dataliance.net">youssef.bouhafs@dataliance.net</a>
                  </li>
                  <li>

                   <a href="tel:+33 1 83 62 12 07">  +33 1 83 62 12 07</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Single Wedget */}
          </div>
        </div>
      </div>
      <div
        className={`${styles.copyright}  ${
          footerBg === "white" ? "bg--cart-8" : "bg--cart-3"
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-6">
              <div className={styles.copyrightInner}>
                <p>© conception et réalisation | Agence digitale<a  href="https://www.fcpo.ma/" target="_blank"> FCPO</a> {année}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <MdExpandLess />
      </button>
    </footer>
  );
};

Footer.propTypes = {
  footerBg: PropTypes.string
};

export default Footer;

import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import ServiceDetailsFour from "../containers/services/serv4";
import Bloggridfour from "../containers/product-grid/product-grid-one";
import Serv5 from "../containers/services/serv5";

const ServiceDetails = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>HT-Call | Téléprospection</title>
        <meta
          name="description"
          content="Service details page of React VOIP, Telecom and Cloud Services Template."
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Téléprospection" />
        {/* service details */}
        <ServiceDetailsFour />
          <Serv5/>
      </LayoutOne>
    </Fragment>
  );
};

export default ServiceDetails;

import React from "react";
import styles from "./ContactForm.module.scss";
import Button from "../../../components/UI/button";
import contactThumb from "../../../assets/images/about/contact.png";
import emailjs from "emailjs-com";

const ContactForm = () => {
  function sendemail(e){
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_xv8lmdl', e.target, 'user_dAssPSGj32qbINz8N41tg')
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
    e.target.reset()
  }
  return (
      <div className="voopo__contact ptb--110">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className={styles.voopoContactForm}>
                <h2>
                  Contactez nous pour plus d'informations
                </h2>
                <form onSubmit={sendemail}>
                  <div className={styles.singleContactForm}>
                    <input type="text" name="name" placeholder="Nom et prénom" />
                  </div>
                  <div className={styles.singleContactForm}>
                    <input
                        type="email"
                        name="email"
                        placeholder="E-mail"
                    />
                  </div>
                  <div className={styles.singleContactForm}>
                    <input type="number"  min="0"   name="number" placeholder="Numéro de téléphone" />
                  </div>
                  <div className={styles.singleContactForm}>
                    <input type="text" name="sujet" placeholder="Sujet" />
                  </div>
                  <div className={`${styles.singleContactForm} message`}>
                  <textarea
                      name="message"
                      placeholder="Tapez votre message"
                      defaultValue={""}
                  />
                  </div>
                  <div className={styles.contactBtn}>
                    <Button type="button" text="Envoyer" />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
              <div className={styles.contactThumb}>
                <img src={contactThumb} alt="Ht-Call voip" />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ContactForm;

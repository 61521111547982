import React from "react";
import Swiper from "react-id-swiper";
import SectionTitle from "../../../components/UI/section-title/section-title-one";
import blogGridData from "../../../data/blog-grid/blog-grid-three.json";
import BlogGridSingle from "../../../components/blog-grid/blog-grid-one/index.js";
import styles from "./BlogGrid.module.scss";

const BlogGrid = () => {
  return (
      <div className="voopo__blog__area bg--cart-5 pb--120">
        <div className="container">
          <div className="row mt--30">
              <div className="col-lg-12">
                  {/* section title */}
                  <SectionTitle title="Notre stratégie Délégation des RH" text="" />
              </div>
            {blogGridData &&
            blogGridData.map((single, key) => {
              return (
                  <BlogGridSingle
                      data={single}
                      key={key}
                      styles={styles}
                      sliderClass="swiper-slide"
                  />
              );
            })}
          </div>
        </div>
      </div>
  );
};

export default BlogGrid;

import React from "react";
import { Link } from "react-router-dom";

const MobileNavMenu = ({ styles }) => {
  return (
    <nav className={styles.offcanvasNavigation} id="offcanvas-navigation">
      <ul>
        <li className={styles.menuItemHasChildren}>
          <Link to={process.env.PUBLIC_URL + "/"}>Acceuil</Link>
        </li>

        <li className={styles.menuItemHasChildren}>
          <Link to={process.env.PUBLIC_URL + "/service"}>Services</Link>
          <ul className={`${styles.subMenu}`}>
            <li>
              <Link to={process.env.PUBLIC_URL + "/assistance-commerciale"}>Assistance commerciale</Link>
            </li>
            <li>
              <Link
                to={process.env.PUBLIC_URL + "/assistance-e-commerce"}
              >
                Assistance E-Commerce
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/delegation-des-rh"}>
                Délégation des RH
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/teleprospection"}>
                Téléprospection
              </Link>
            </li>
          </ul>

        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/apropos"}>À propos</Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;

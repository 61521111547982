import React from "react";
import styles from "./Navigation.module.scss";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <nav>
      <ul className={styles.mainMenu}>
        <li className={styles.drop}>
          <Link to={process.env.PUBLIC_URL + "/"}>
            Acceuil
          </Link>
        </li>
        <li className={styles.drop}>
          <Link to={process.env.PUBLIC_URL + "/service"}>
            Services <IoIosArrowDown />
          </Link>
          <ul className={styles.dropdownMenu}>
            <li>
              <Link to={process.env.PUBLIC_URL + "/assistance-commerciale"}>Assistance commerciale</Link>
            </li>
            <li>
              <Link
                to={process.env.PUBLIC_URL + "/assistance-e-commerce"}
              >
                Assistance E-Commerce
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/delegation-des-rh"}>
                Délégation des RH
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/teleprospection"}>
                Téléprospection
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/apropos"}> À propos</Link>
        </li>

        <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;

import PropTypes from "prop-types";
import React from "react";
import { MdContactPhone } from "react-icons/md";
import {Link} from "react-router-dom";

const ServiceTwoSingle = ({ data, styles }) => {
  return (
    <div className="col-12 col-lg-12 col-xl-6 sm__mt--40">
      <div className={styles.bestService}>
        <div
          className={styles.icon}
          style={{
            background: `url(${require("./../../../assets/images/icons/" +
              data.icon)}) no-repeat scroll left top`
          }}
        >
          <MdContactPhone />
        </div>
        <div className={styles.content}>
          <h2><a href="/assistance-commerciale">{data.title1}</a></h2>
          <h2><a href="/assistance-e-commerce">{data.title2}</a></h2>
          <h2><a href="/delegation-des-rh">{data.title3}</a></h2>
          <h2><a href="/teleprospection">{data.title4}</a></h2>

          <p> <a className="text-info"href="/assistance-commerciale">{data.text1}</a></p>
          <p> <a className="text-info"href="/assistance-e-commerce">{data.text2}</a></p>
          <p> <a className="text-info"href="/delegation-des-rh">{data.text3}</a></p>
          <p> <a className="text-info"href="/teleprospection"><hr class="visibility:hidden;"/>{data.text4}</a></p>
        </div>
      </div>
    </div>
  );
};

ServiceTwoSingle.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default ServiceTwoSingle;

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import softwareContent from "../../../data/software-download/software-download-one.json";

const BlogGridSingle = ({ data, styles, sliderClass }) => {
  return (
    <div
      className={`col-12 col-sm-6 col-lg-4 ${sliderClass ? sliderClass : ""}`}
    >
      <div className={styles.blog}>
        <div className={styles.thumb}>
          <Link>
            <img
              src={require("./../../../assets/images/blog-img/" + data.image)}
              alt="HT-Call voip"
            />
          </Link>
        </div>
        <div className={styles.blogInner}>
          <div className={styles.content}>

            <h2 className="text-secondary">
             {data.title}
            </h2>
            <div
                dangerouslySetInnerHTML={{ __html: data.excerpt }}
            />
          </div>

        </div>
      </div>
    </div>
  );
};

BlogGridSingle.propTypes = {
  data: PropTypes.object,
  styles: PropTypes.object
};

export default BlogGridSingle;

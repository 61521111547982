import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import ServiceDetailsOne from "../containers/services/serv1";
import Bloggridone from "../containers/blog-grid/blog-grid-one";



const ServiceDetails = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>HT-Call | Assistance commerciale</title>
        <meta
          name="description"
          content="Service details page of React VOIP, Telecom and Cloud Services Template."
        />
      </MetaTags>
      <LayoutOne>

        <Breadcrumb title="Assistance commerciale" />
        <ServiceDetailsOne />
        <Bloggridone />

      </LayoutOne>
    </Fragment>
  );
};

export default ServiceDetails;

import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaPinterestP,
  FaPhone,
  FaRegEnvelope
} from "react-icons/fa";

const MobileWidgets = ({ styles }) => {
  return (
    <div className={styles.offcanvasWidgetArea}>
      <div className={styles.offCanvasContactWidget}>
        <div className={styles.headerContactInfo}>
          <ul className={styles.headerContactInfoList}>


          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}

      {/*Off Canvas Widget Social End*/}
    </div>
  );
};

export default MobileWidgets;

import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import FeatureImageTextOne from "../containers/feature-image-texts/feature-image-text-one";
import ServiceFour from "../containers/services/service-four";
import AboutContentOne from "../containers/about-contents/about-content-one";
import AboutContentTwo from "../containers/about-contents/about-content-two";
import {Link} from "react-router-dom";
import ServiceThree from "../containers/services/service-three";

const About = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>HT-Call | À propos</title>
        <meta
          name="description"
          content="About page of React VOIP, Telecom and Cloud Services Template."
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="À propos" />

        {/* about content */}
        <AboutContentOne />
          {/* about content */}
          <AboutContentTwo />


      </LayoutOne>
    </Fragment>
  );
};

export default About;

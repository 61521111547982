import React from "react";
import LocationMap from "../../../components/contact/contact-map";
import styles from "./ContactMap.module.scss";
import Button from "../../../components/UI/button";
import contactThumb from "../../../assets/images/about/coo.png";

const ContactMap = () => {
  return (
      <div className={`${styles.voopoAddress} bg--cart-10`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-5">
              <div className={styles.vpContactAddress}>
                <h2>
                  Contact informations
                </h2>
                {/* Start Single Address */}
                <div className={styles.vpAddress}>
                  <h4>Email</h4>
                  <p>
                    <a href="mailto:youssef.bouhafs@dataliance.net">youssef.bouhafs@dataliance.net</a>
                  </p>
                </div>
                {/* End Single Address */}
                {/* Start Single Address */}
                <div className={styles.vpAddress}>
                  <h4>Téléphone</h4>
                  <p>
                    <a href="tel:+33 1 83 62 12 07">+33 1 83 62 12 07</a>
                  </p>

                </div>
                {/* End Single Address */}
                {/* Start Single Address */}
                <div className={styles.vpAddress}>
                  <h4>WhatsApp</h4>
                  <p>
                    <a href="tel:+33 6 21 45 14 44">+33 6 21 45 14 44</a>
                  </p>

                </div>
                {/* End Single Address */}

              </div>
            </div>
            <div>
              <div className={styles.contactThumb}>
                <img src={contactThumb} alt="Ht-Call voip"  />
              </div>

            </div>
          </div>
        </div>
      </div>
  );
};

export default ContactMap;

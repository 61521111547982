import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import serviceData from "../../../data/services/serv5.json";
import { MdPlayArrow } from "react-icons/md";
import bgImg from "../../../assets/images/bg/7.jpg";
import styles from "./ServiceFour.module.scss";

const Serv4 = () => {
  const [modalStatus, isOpen] = useState(false);
  return (
    <div className={`vp__service ${styles.service3} position-relative`}>
      <div className="container-fluid">

        <div className="row no-gutters">
          <div className="col-12 col-lg-6">
            <div className={styles.thumb}>
              <img
                  src={require("./../../../assets/images/about/" +
                      serviceData.image)}
                  alt="Ht-Call voip"
              />
            </div>
          </div>
          <div
            className="col-12 col-lg-6"
          >
            <div className={styles.serviceInner}>
              <h2>{serviceData.title}</h2>
              <p>{serviceData.content}</p>
                <p>{serviceData.content2}</p>
              <p>{serviceData.content3}</p>
              <p>{serviceData.content4}</p>
              <p className="font-weight-normal , ml-3">{serviceData.content5}</p>
              <p className="font-weight-normal , ml-3">{serviceData.content6}</p>
              <p className="font-weight-normal , ml-3">{serviceData.content7}</p>
              <p className="font-weight-normal , ml-3">{serviceData.content8}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Serv4;
